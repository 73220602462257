<template>
  <div id="topBac">
    <div class="alreadyTitleRes">
      <p class="type">
        {{workStatusList[orderState]}}
      </p>
      <div class="typeWrap">
        <slot name="orderTime">
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    orderState: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      workStatusList: {
        "-1": "交易关闭",
        "-2": "遗留订单",
        0: "商家已下单",
        1: "待预约",
        2: "预约失败",
        3: "预约成功",
        4: "待提货",
        5: "提货失败",
        6: "提货成功",
        7: "签到失败",
        8: "已签到,待验货",
        9: "验货异常",
        10: "验货成功,待完工",
        11: "完工失败",
        12: "完工成功,待验收",
        13: "验收成功",
        14: "验收失败",
        15: "待退款",
        16: "退款成功",
        17: "交易成功",
      },
    };
  },
};
</script>
<style lang="less">
#topBac {
  background: #ff816c;
  height: 20rem;
  margin-bottom: 6.5rem;
  img {
    width: 100%;
  }
  .alreadyTitleRes {
    width: 100%;
    .typeWrap {
      width: 95%;
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      align-items: left;
      padding: 2rem 2rem;
      box-sizing: border-box;
      box-shadow: -1px 0px 6px 0 #f0f0f0;
      .info {
        color: #000;
        font-size: 1.4rem;
        padding: 0.5rem 0;
      }
      .des {
        padding-left: 1rem;
      }
    }
    .type {
      font-size: 2.5rem;
      font-weight: 600;
      margin: 1.5rem;
    }
    color: #fff;
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
  }
}
</style>