<template>
  <div id="Installcountdown">
    <!-- 倒计时 -->
    <div class="countdown">
      <div class="leftInfo">
        <span class="timeOut">
          <svg
            t="1602129047857"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="1263"
            width="20"
            height="20"
          >
            <path
              d="M512 80c58.355 0 114.922 11.411 168.129 33.916 51.434 21.754 97.641 52.914 137.341 92.614s70.86 85.908 92.614 137.34C932.589 397.078 944 453.645 944 512c0 58.355-11.411 114.922-33.916 168.129-21.754 51.434-52.914 97.641-92.614 137.341s-85.907 70.86-137.341 92.614C626.922 932.589 570.355 944 512 944c-58.355 0-114.922-11.411-168.129-33.916-51.433-21.754-97.641-52.914-137.341-92.614s-70.86-85.907-92.614-137.341C91.411 626.922 80 570.355 80 512c0-58.355 11.411-114.922 33.916-168.129 21.754-51.433 52.915-97.641 92.614-137.34 39.7-39.7 85.908-70.86 137.341-92.614C397.078 91.411 453.645 80 512 80m0-80C229.23 0 0 229.23 0 512s229.23 512 512 512 512-229.23 512-512S794.77 0 512 0z"
              p-id="1264"
              fill="#ffffff"
            ></path>
            <path
              d="M469.48 115.15h85.04V512h-85.04z"
              p-id="1265"
              fill="#ffffff"
            ></path>
            <path
              d="M469.48 469.481h297.637v85.039H469.48z"
              p-id="1266"
              fill="#ffffff"
            ></path>
          </svg>
          <!-- <svg t="1600918061470" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4198" width="24" height="24"><path d="M448 456V96q0-12.992 9.504-22.496T480 64h192q14.016 0 23.008 8.992T704 96t-8.992 23.008T672 128h-160v128h160q14.016 0 23.008 8.992T704 288t-8.992 23.008T672 320h-160v128q104 2.016 175.008 69.504t80 170.496q4 104-58.496 179.488T544 957.984q-104 11.008-183.008-46.496T260 752q-16.992-102.016 35.488-184.992T448 456zM512 896q82.016-2.016 136-56T704 704q-2.016-82.016-56-136T512 512q-82.016 2.016-136 56T320 704q2.016 82.016 56 136T512 896z" p-id="4199" fill="#ffffff"></path></svg> -->
          <p style="margin: 0.4rem">距离报价结束</p>
        </span>
        <span> </span>
      </div>
      <div class="rightCountdown">
        <van-count-down :time="time" format="DD天HH时mm分ss秒" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
    };
  },
};
</script>
<style lang="less">
#Installcountdown {
  .countdown {
    display: flex;
    height: 6.5rem;
    background: #FF7963;
    color: #fff;
    .leftInfo {
      width: 30%;
      background: #ff634a;
      font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .timeOut {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
    }
    .rightCountdown {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        margin-right: 0.5rem;
        height: 20px;
      }
    }
    .van-count-down {
      color: #fff;
      font-size: 1.7rem;
      letter-spacing: 0.1rem;
    }
  }
}
</style>