<template>
  <!-- 订单详情页面 -->
  <div id="ReservationDetails" v-if="ready">
    <van-nav-bar title="订单详情" left-arrow @click-left="goLastPage" :border="false" />
    <ProductInfor :product_detail="OrderDetails.order" :imgList="orderImgList" :needButton="false" />
  </div>
  <div id="loading" v-else>
    <van-loading type="spinner" color="#FF6046" vertical />
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import TopState from "@/components/order/TopState.vue";
import Installcountdown from "@/components/order/Installcountdown.vue";
import InstallAdress from "@/components/order/InstallAdress.vue";
import ProductInfor from "@/components/order/ProductInfor.vue";
import BottomNav from "@/components/NavBar/BottomNav.vue";
import store from "@/store/index.js";
export default {
  data() {
    return {
      orderImgList: [],
      OrderDetails: {},
      codeType: ["待预约客户"],
      ready: false,
      orderId: "",
    };
  },
  components: {
    TopBar,
    Installcountdown,
    InstallAdress,
    ProductInfor,
    BottomNav,
    TopState,
  },
  methods: {
    toLastIndex() {
      this.$router.go(-1);
    },
  },
  created() {
    this.orderId = this.$route.params.orderId;
    this.$http.get(`/api/v2/worker/order/${this.orderId}`).then((res) => {
      console.log(res, "ss");
      this.OrderDetails = res.data;
      this.ready = true;
    });
  },
};
</script>
<style lang="less">
#loading {
  height: 100vh;
  width: 100vw;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#ReservationDetails {
  background: #f0f0f0;
  padding-bottom: 46px;
  // margin-bottom: 46px;
  .alreadyTitleRes {
    width: 100%;
    .typeWrap {
      width: 95%;
      background: #fff;
      border-radius: 10px;
      margin: 0 auto;
      align-items: left;
      padding: 2rem 2rem;
      box-sizing: border-box;
      box-shadow: -1px 0px 6px 0 #f0f0f0;
      .info {
        color: #000;
        font-size: 1.4rem;
        padding: 0.5rem 0;
      }
      .des {
        padding-left: 1rem;
      }
    }
    .type {
      font-size: 2.5rem;
      font-weight: 600;
      margin: 1.5rem;
    }
    color: #fff;
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
  }
  .topTitle {
    font-size: 1.8rem;
    font-weight: 400;
    color: #000;
  }

  .bottomButton {
    width: 100%;
    // margin-bottom: 160px;
    // margin-bottom: 60px;
    background: linear-gradient(to right, #ff6034, #ee0a24);
    height: 50px;
    font-size: 1.7rem;
    // font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 50px;
  }
}
</style>