<template>
  <div id="installAdress">
    <ul>
      <li class="publicList">
        <span class="publicLeft">安装项目</span>
        <span class="publicCenter" v-if="OrderInfo.service_mode">{{ OrderInfo.service_mode }}
        </span>
        <span class="publicCenter" v-else>
          <span style="
              width: 15rem;
              height: 2rem;
              background: #f2f2f2;
              border-radius: 2px;
            "></span>
        </span>
        <!-- <span class="publicRight"> -->
        <!-- </span> -->
      </li>
      <li class="publicList">
        <span class="publicLeft">客户姓名</span>
        <span class="publicCenter" v-if="OrderInfo.name">{{ OrderInfo.name }} {{ OrderInfo.mobile }}</span>
        <span class="publicCenter" v-else>
          <span style="
              width: 15rem;
              height: 2rem;
              background: #f2f2f2;
              border-radius: 2px;
            "></span>
        </span>
        <span class="publicRight">
          <a :href="`tel:${OrderInfo.contact_mobile}`">
            <svg t="1600925734899" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3461" width="22" height="22">
              <path d="M789.85475 713.420429c-56.890708-21.75549-110.774943-49.748031-173.238576 8.559956-38.553061 35.985586-66.429968 32.04688-91.638093 18.648732C499.786329 727.233016 344.582501 568.779168 323.393923 516.943592c-21.186532-51.835575-9.742898-58.84113 0-74.553996 9.728572-15.735379 77.310783-52.43728 36.652781-167.765935-40.672328-115.331725-72.604598-202.183815-238.250242 0-17.183358 20.969591 0 111.843274 0 111.843274s116.799146 381.794519 513.137916 540.566616C696.461687 951.674765 835.415412 912.650983 891.505895 815.18823 918.999063 767.42439 879.124913 747.554854 789.85475 713.420429z" p-id="3462" fill="#FF6046"></path>
            </svg>
          </a>
        </span>
      </li>
      <li class="publicList lastList">
        <span class="publicLeft">安装地址</span>
        <span class="publicCenter mapBac" v-if="OrderInfo.address">{{
              `${(OrderInfo.address.parent.parent? OrderInfo.address.parent.parent.name: '')} ${OrderInfo.address.parent.name} ${OrderInfo.address.name} ${OrderInfo.address_suffix}`
            }}
        </span>
        <span class="publicCenter" v-else>
          <span style="
              width: 15rem;
              height: 2rem;
              background: #f2f2f2;
              border-radius: 2px;
            "></span>
        </span>
        <span class="publicRight">
          <svg t="1600926049183" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4440" width="22" height="22" @click="map1">
            <path d="M516.320553 65.913723C327.473365 66.759049 174.512778 221.085162 175.350878 409.954025c0.787526 177.21493 282.974557 496.596289 315.154731 532.417865a39.737529 39.737529 0 0 0 59.440119-0.2601c31.862273-36.110576 311.202653-357.991787 310.415127-535.213942-0.838101-188.861638-155.178664-341.822225-344.040302-340.984125z m2.196402 492.860961c-76.483906 0.339575-139.009102-61.614845-139.348677-138.105976-0.339575-76.491131 61.629295-139.001877 138.105976-139.334227 76.512806-0.3468 139.023552 61.60762 139.363127 138.091526 0.339575 76.498356-61.614845 139.001877-138.120426 139.348677z" fill="#FF6046" p-id="4441"></path>
          </svg>
          导航
        </span>
      </li>
      <!-- <li class="publicList lastList">
        <span class="publicLeft">备注</span>
        <span class="publicCenter"> 
          <input type="text" placeholder="在次输入备注信息">
        </span>
        <span class="publicRight">
          <svg
            t="1600926155079"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5063"
            width="22"
            height="22"
          >
            <path
              d="M960.3 925.6c0-22.6-18.3-41-41-41H100.1c-22.6 0-41 18.3-41 41 0 22.6 18.3 41 41 41h819.3c22.6-0.1 40.9-18.4 40.9-41zM874.9 245.5c-0.1-0.1-0.3-0.2-0.4-0.4L695.1 65.7c-26.7-23.2-67.3-22.2-92.7 3.3-0.1 0.1-0.2 0.3-0.4 0.4L135.4 535.9c-9.4 9.4-13.3 22.3-11.6 34.6v209.1c0 2.1 0.2 4.2 0.5 6.2 0.2 1.4 0.5 2.7 0.8 4 0.7 2.6 1.6 5.1 2.7 7.5 0.6 1.2 1.2 2.4 1.9 3.5 0.3 0.6 0.7 1.1 1.1 1.7 0.7 1.1 1.5 2.1 2.4 3.2 3.6 4.4 8.1 8 13.2 10.6l1.2 0.6c5.2 2.4 11 3.8 17.2 3.8h214.3c13.2 0 25-6.3 32.4-16l467.8-467.8c22-26.8 20.5-66.4-4.4-91.4z"
              fill="#FF6046"
              p-id="5064"
            ></path>
          </svg>
        </span>
      </li> -->
    </ul>
  </div>
</template>>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    OrderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      newCenter: [],
      adress: "合肥市",
    };
  },
  methods: {
    map1() {
      this.show = true;
      this.adress = `${
        this.OrderInfo.address.parent.parent
          ? this.OrderInfo.address.parent.parent.name
          : ""
      } ${this.OrderInfo.address.parent.name} ${this.OrderInfo.address.name}  ${
        this.OrderInfo.address_suffix
      }`;
      this.$ajax
        .get(
          "https://restapi.amap.com/v3/geocode/geo?key=d70cea9ba6be99be2fd08dc1aba72b96&s=rsv3&&address=" +
            this.adress
        )
        .then((res) => {
          this.newCenter = res.data.geocodes[0].location.split(",");

          let map = new AMap.Map("installAdress", {
            resizeEnable: true,
            center: [120.076766, 26.96673],
          });
          let marker = new AMap.Marker({
            map: map,
            position: this.newCenter,
          });
          // marker.setLabel({
          //   offset: new AMap.Pixel(20, 20), //修改label相对于maker的位置
          //   content: "点击导航至服务处",
          // });
          // marker.on("click", function (e) {
          marker.markOnAMAP({
            name: this.adress,
            position: marker.getPosition(),
          });
          // });
          map.addControl(new AMap.ToolBar());
          // if (AMap.UA.mobile) {
          //   document.getElementById("button_group").style.display = "none";
          // }
        });
    },
  },
  mounted() {
    // let adressT = `${this.OrderInfo.address.province}`
  },
};
</script>
<style lang="less">
#installAdress {
  padding: 1rem;
  margin: 1rem 1rem 0.7rem 1rem;
  border-radius: 10px;
  .amap-wrapper {
    width: 1440px;
    height: 600px;
  }
  background: #fff;
  .publicList {
    width: 100%;
    height: 5rem;
    display: flex;
    font-size: 1.4rem;
    border-bottom: 1px solid #f2f2f2;
    justify-content: space-between;
    &.lastList {
      border: none;
    }
    &.firstList {
      border: none;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      &.publicLeft {
        width: 20%;
        color: #7e7e7e;
        font-size: 1.4rem;
      }
      &.publicRight {
        width: 10%;
        display: flex;
        font-size: 1.2rem;
        flex-direction: column;
      }
      &.publicCenter {
        flex: 1;
        justify-content: left;
        padding: 0 1.2rem;
        box-sizing: border-box;
        &.mapBac {
          background: url("../../assets/images/mapBac.png") no-repeat center /
            cover;
        }
        input {
          border: none;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>